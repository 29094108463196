


import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";
import PreRequisiteHeader from "@/views/preRequisiteHeader/PreRequisiteHeader.vue";

@Component({
  components: {
    PreRequisiteHeader
  }
})
export default class ForgotPasswordComponent extends Vue {
  public confirmationId: any = null;
  public errorMessage: any = null;
  public inProcess = false;
  public forgotPassword: any = {};
  public successMessage: any = null;
  public emailNotFound: boolean = false;
  public invalidNewPassword: boolean = false;
  public invalidNewPasswordMessage: string = null;

/**
 * Submitting the data to the server for changing the passsword
 */
  public async validateAndSubmit() {
    try {
      let formValid = await this.$validator.validateAll();
      
      if (formValid) {
        this.inProcess = true;

        let apiUrl = this.confirmationId
          ? "authentication/confirmPasswordChange"
          : "authentication/sendPasswordChangeLink";
        this.forgotPassword["token"] = this.confirmationId;

        await Axios.post(BASE_API_URL + apiUrl, this.forgotPassword);

        if (this.confirmationId) {
          this.$router.replace({ name: "Login" });
          this.$snotify.success("Password changed successfully.");
        } else {
          this.successMessage = "If your account is found, you will receive a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder."
          this.$snotify.success(this.successMessage)
          this.forgotPassword = {};
        }

        return;
      }
    } catch (error) {
      let errorInfo = error.response;
      if (errorInfo.status == 400) {
        if ((errorInfo.data.type = "invalidNewPassword")) {
          this.invalidNewPassword = true;
          this.invalidNewPasswordMessage = errorInfo.data.message;
        } else {
          this.errorMessage = errorInfo.data.message;
        }
      }
      if (errorInfo.status == 403) {
        this.errorMessage = errorInfo.data.message;
      }
    } finally {
      this.inProcess = false;
    }
  }

  public removeMessages() {
  }

  mounted() {
    this.confirmationId = this.$route.query.forgot_password_id;
  }
}
